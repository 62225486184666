import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24')
];

export const server_loads = [];

export const dictionary = {
		"/": [~3],
		"/contact": [4],
		"/new": [~5],
		"/privacy": [6],
		"/sentry-example": [7],
		"/session-selector": [~23],
		"/session/[sessionId]/invited": [~8,[2]],
		"/session/[sessionId]/user/[userId]/history": [9,[2]],
		"/session/[sessionId]/user/[userId]/intro": [10,[2]],
		"/session/[sessionId]/user/[userId]/invite": [~11,[2]],
		"/session/[sessionId]/user/[userId]/punishments": [~12,[2]],
		"/session/[sessionId]/user/[userId]/punishments/[punishmentId]": [~13,[2]],
		"/session/[sessionId]/user/[userId]/rewards": [~14,[2]],
		"/session/[sessionId]/user/[userId]/rewards/[rewardId]": [~15,[2]],
		"/session/[sessionId]/user/[userId]/session-management": [16,[2]],
		"/session/[sessionId]/user/[userId]/session-management/edit-session": [~17,[2]],
		"/session/[sessionId]/user/[userId]/session-management/edit-user": [~18,[2]],
		"/session/[sessionId]/user/[userId]/session-management/user-management": [~19,[2]],
		"/session/[sessionId]/user/[userId]/session-management/user-management/add-user": [~20,[2]],
		"/session/[sessionId]/user/[userId]/tasks": [~21,[2]],
		"/session/[sessionId]/user/[userId]/tasks/[taskId]": [~22,[2]],
		"/terms": [24]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';