// hooks.client.js
import { init } from '@jill64/sentry-sveltekit-cloudflare/client'
import { dev } from '$app/environment';
import * as Sentry from '@sentry/browser'
import { env } from '$env/dynamic/public';
// or
// import { clientInit } from '@jill64/sentry-sveltekit-cloudflare'

const onError = init(
    'https://ee76c27a0c8f0140ff249128343490f6@o1258517.ingest.us.sentry.io/4508326039715840'
    ,
    {
        sentryOptions: {
            // tunnel: '/tunnel',
            integrations: [
                Sentry.browserTracingIntegration(),
                // Sentry.browserProfilingIntegration(),
                Sentry.replayIntegration()
            ],
            tracesSampleRate: 1.0,
            profilesSampleRate: 1.0,
            environment: env.CF_PAGES ? "production" : "dev",
            // we only care about errors
            replaysSessionSampleRate: 0,
            replaysOnErrorSampleRate: 1.0
        },
        enableInDevMode: dev
    }
)

export const handleError = onError((e, sentryEventId) => {
    // Your Error Handler
})